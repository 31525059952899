export const environment = {
  production: true,
  api_url : 'https://cloud.evologics.de',
  oidConfig: {
      issuer: 'https://sso.evologics.com/application/o/evocloud/',
      clientId: '2zUdFWlVEKR0GtLKycQFhypJfF3TPRiuINqUuVes', // The "Auth Code + PKCE" client
      dummyClientSecret: '3eMBheHRPbxz9SZZIg70t7gIEfRvgtigSBi74ZG2w0bFpMCi3TSVuxkRSQ9yKMLfnIFEn1KH0Hb0TZBlpdTjAfSTGjaBIdFlJLlkS4s88hcTw0dycH82TumsIrqaXQOv',
      responseType: 'code',
      redirectUri: 'https://cloud.evologics.de/oauth_callback',
      // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      scope: 'openid profile email offline_access', // Ask offline_access to support refresh token refreshes
      useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
      // silentRefreshTimeout: 5000, // For faster testing
      // timeoutFactor: 0.01,
      sessionChecksEnabled: true,
      showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
      clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
      nonceStateSeparator : 'semicolon', // Real semicolon gets mangled by Duende ID Server's URI encoding
      strictDiscoveryDocumentValidation: false,
      oidc: true
    }
};
